import { ClaimsService } from "@/services/apiService";
// import { COLLECTABLE_TYPE } from "@/constants/Claims.js";
import { computed, reactive } from "vue";


export default function useClaimsWithPagination() {
    const state = reactive({
        items: [],
        perPage: 5,
        page: 1,
        hasMore: false,
        filter: false,
        totalRecords: 0,
    });

    async function load(perPage = 5, includeHiddenDrops = true) {
        state.perPage = perPage;
        state.page = 1;
        state.hasMore = false;

        state.items = [];

        const query = {
        perPage: state.perPage,
        page: state.page,
        includeIsHiddenFromDropList: includeHiddenDrops,
        };

        const { data, metadata } = await ClaimsService.list(
        query,
        { type: state.filter },
        );

        state.items = data;
        state.hasMore =
        metadata.pagination.totalPages > state.page;
        state.totalRecords = metadata.pagination.total;
    }

    async function loadMore() {
        if (!state.hasMore) return;

        let query = {
            perPage: state.perPage,
            page: state.page + 1,
            includeIsHiddenFromDropList: true,
        };

        state.items = [
            ...state.items
        ];

        const { data, metadata } = await ClaimsService.list(
        query,
        { type: state.filter },
        );

        state.page += 1;
        state.items = [
        ...state.items.filter((i) => i != null),
        ...data,
        ];

        state.hasMore =
        metadata.pagination.totalPages !== state.page;
        state.totalRecords = metadata.pagination.total;
    }

    function getFilter(filterNft, filterTangibleNft) {
        // if (filterNft && filterTangibleNft)
        // return COLLECTABLE_TYPE.NONE;
        // if (filterNft)
        // return COLLECTABLE_TYPE.NFT;
        // if (filterTangibleNft)
        // return COLLECTABLE_TYPE.TANGIBLE_NFT;

        // return COLLECTABLE_TYPE.TANGIBLE;
        return null;
    }

    function filter(filterNft, filterTangibleNft) {
        state.filter = getFilter(filterNft, filterTangibleNft);
        load();
    }

    const listOfClaims = computed(() => state.items);
    const hasMore = computed(() => state.hasMore);
    const totalRecords = computed(() => state.totalRecords);

    return {
        listOfClaims,
        hasMore,
        totalRecords,
        filter,
        load,
        loadMore,
    };
}