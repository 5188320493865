<template>
  <container class="max-width-full collectable-detail mx-auto px-6 md:px-8 py-10 py-16">
    <h1 class="text-3xl font-bold text-center uppercase mb-16">
      Collectable Claims
    </h1>
    <DataTable ref="dt" :lazy="true" :value="displayClaims" :loading="loading" :paginator="true" :rows="rows" :totalRecords="totalRecords"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        @page="onPage($event)"
        responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
        <Column headerClass="no-break" field="id" header="ID"></Column>
        <Column headerClass="no-break" bodyClass="no-break" field="collectable.title" header="Collectable">
            <template #body="slotProps">
                <a target="_blank" rel="noreferrer noopener" style="color: #0072ff;" :href="`https://seen.haus/${slotProps.data.collectable.is_slug_full_route ? '' : 'drops/'}` + slotProps.data.collectable.slug">
                    {{slotProps.data.collectable.title}}
                </a>
            </template>
        </Column>
        <Column headerClass="no-break" field="current_claimed_nft_balance" header="Current Balance">
            <template #body="slotProps">
                <pre>
                    {{slotProps.data.current_claimed_nft_balance}}
                </pre>
            </template>
        </Column>
        <Column headerClass="no-break" field="wallet_address" header="Wallet Address">
            <template #body="slotProps">
                <a target="_blank" rel="noreferrer noopener" style="color: #0072ff;" :href="'https://opensea.io/' + slotProps.data.wallet_address">{{slotProps.data.wallet_address}}</a>
            </template>
        </Column>
        <Column headerClass="no-break" field="first_name" header="First Name"></Column>
        <Column headerClass="no-break" field="last_name" header="Last Name"></Column>
        <Column headerClass="no-break" field="email" header="Email">
            <template #body="slotProps">
                <a style="color: #0072ff;" :href="'mailto:' + slotProps.data.email">{{slotProps.data.email}}</a>
            </template>
        </Column>
        <Column headerClass="no-break" field="message" header="Message"></Column>
        <Column headerClass="no-break" field="address" header="Address"></Column>
        <Column headerClass="no-break" field="city" header="City"></Column>
        <Column headerClass="no-break" field="zip" header="Zip"></Column>
        <Column headerClass="no-break" field="province" header="Province"></Column>
        <Column headerClass="no-break" field="country" header="Country"></Column>
        <Column headerClass="no-break" field="phone" header="Phone"></Column>
        <Column headerClass="no-break" field="telegram_username" header="Telegram"></Column>
        <Column headerClass="no-break" field="created_at" header="Claimed At"></Column>
    </DataTable>
  </container>
</template>


<script>
import Container from "@/components/Container.vue";
import {computed, ref, onMounted} from "vue";
import { useSeenNFTContract } from "@/hooks/useContract.js";
import useClaimsWithPagination from "@/hooks/useClaimsWithPagination.js";

export default {
  name: "Claims",
  components: { Container },
  async setup() {

    const seenNftContract = useSeenNFTContract("0x13bAb10a88fc5F6c77b87878d71c9F1707D2688A")

    onMounted(() => {
        loading.value = true;
    
        lazyParams.value = {
            first: 0,
            rows: dt.value.rows,
            sortField: null,
            sortOrder: null,
            filters: filters.value
        };

        loadLazyData();
    })

    const dt = ref();
    const lazyParams = ref({});
    const filters = ref({});
    const displayClaims = ref();
    const rows = 5;

    const loading = ref(false);

    const loadLazyData = async () => {
        loading.value = true;
        
        if(listOfClaims.value && listOfClaims.value.length > 0) {
            if(listOfClaims.value.length < lazyParams.value.first + lazyParams.value.rows) {
                let pagesSkipped = 0;
                if(lazyParams.value.first - listOfClaims.value.length > 0) {
                    pagesSkipped = (lazyParams.value.first - listOfClaims.value.length) / rows
                }
                console.log({'lazyParams.value': lazyParams.value, 'listOfClaims.value.length': listOfClaims.value.length, pagesSkipped})
                if(pagesSkipped > 0) {
                    for(let i = 0; i < pagesSkipped; i++) {
                        await paginatedClaims.loadMore()
                    }
                }
                paginatedClaims.loadMore().then(async () => {
                    displayClaims.value = await fillBalances(listOfClaims.value.slice(lazyParams.value.first, lazyParams.value.first + lazyParams.value.rows));
                    loading.value = false;
                })
            }else{
                displayClaims.value = await fillBalances(listOfClaims.value.slice(lazyParams.value.first, lazyParams.value.first + lazyParams.value.rows));
                loading.value = false;
            }
        }else{
            paginatedClaims.load(rows).then(async () => {
                displayClaims.value = await fillBalances(listOfClaims.value.slice(lazyParams.value.first, lazyParams.value.first + lazyParams.value.rows));
                loading.value = false;
            })
        }
    };

    const fillBalances = async (listItems) => {
        for(let listItem of listItems) {
            if(listItem.wallet_address && listItem.collectable && (listItem.collectable.nft_contract_address === "0x13bAb10a88fc5F6c77b87878d71c9F1707D2688A" || listItem.collectable.nft_contract_address === "0xf35a31989fB00dD4e491813441E9F0E2Bc3FEe3F")) {
                if((listItem.collectable.nft_token_id.constructor === Array)) {
                    let tokenIds = listItem.collectable.nft_token_id;
                    for(let tokenId of tokenIds) {
                        let balanceOfCurrentAccount = await seenNftContract.balanceOf(listItem.wallet_address, tokenId);
                        if(listItem.current_claimed_nft_balance) {
                            listItem.current_claimed_nft_balance[tokenId] = parseInt(balanceOfCurrentAccount);
                        } else {
                            listItem.current_claimed_nft_balance = {};
                            listItem.current_claimed_nft_balance[tokenId] = parseInt(balanceOfCurrentAccount);
                        }
                    }
                    listItem.current_claimed_nft_balance = JSON.stringify(listItem.current_claimed_nft_balance, null, 4)
                }else {
                    let balanceOfCurrentAccount = await seenNftContract.balanceOf(listItem.wallet_address, listItem.collectable.nft_token_id);
                    listItem.current_claimed_nft_balance = parseInt(balanceOfCurrentAccount);
                }
            } else {
                listItem.current_claimed_nft_balance = "n/a";
            }
        }
        return listItems;
    }

    const onPage = (event) => {
        lazyParams.value = event;
        loadLazyData();
    };

    const paginatedClaims = useClaimsWithPagination();

    const listOfClaims = computed(
      () => paginatedClaims.listOfClaims.value
    );

    const totalRecords = computed(
      () => paginatedClaims.totalRecords.value
    );

    return {
        dt,
        displayClaims,
        totalRecords,
        onPage,
        loading,
        rows,
    };
  },
};
</script>

<style scoped>
</style>
