import useWeb3 from "@/connectors/hooks"
import {getContract} from "@/services/utils"
import ERC20_ABI from "@/constants/abis/erc20.json"
import SEEN_NFT_ABI from "@/constants/abis/SeenNFT.json"
import {Web3Provider, WebSocketProvider} from "@ethersproject/providers";

function useContract(address, ABI, withSignerIfPossible = true) {
    const {account, provider} = useWeb3()
    const library = provider.value
        ? new Web3Provider(provider.value)
        : new WebSocketProvider("wss://mainnet.infura.io/ws/v3/41957fe0f9d248d6b73666c35869d555")

    try {
        return getContract(address, ABI, library, withSignerIfPossible && account.value ? account.value : undefined)
    } catch (error) {
        console.error('Failed to get contract', error)
        return null
    }
}

export function useTokenContract(tokenAddress, withSignerIfPossible) {
    return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useSeenNFTContract(contract, withSignerIfPossible) {
    return useContract(contract, SEEN_NFT_ABI, withSignerIfPossible)
}